import { useGlobalStore } from "@/store/global"

export default defineNuxtRouteMiddleware(async (to, from) => {

    if (process.server) {
        return;
    }

    if(from.fullPath === "/"){
        return;
    }

    const store = useGlobalStore()
    store.handleActiveVerses(null)

    if (to.params.translation != from.params.translation) {
        store.clearTexts()
        await store.selectTranslation(to.params.translation)
        await store.selectBook(to.params.book) // kvôli dátam o knihe
    }

    if (to.params.book != from.params.book) {
        store.clearTexts()
        await store.selectBook(to.params.book, true)
    }

    if (to.params.chapter != from.params.chapter) {
        store.clearTexts()
        await store.selectChapter(to.params.chapter)
    }

    await store.fetchTexts()

})
